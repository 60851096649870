.accordion-container {
  background-color: #262626;
  width: 100%;
  font-family: 'erbaum';
  margin: 0 5%;
}

.accordion-row {
  .title-toggle {
    width: 100%;
    font-size: 1vw;
    text-align: left;
    padding: 1.5% 2%;
    background-color: #262626;
    border: none;
    color: white;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    border-bottom: 5px solid black;
  }
  .icon-toggle {
    transition: transform 0.5s;
  }
  .active {
    background-color: black;
    .icon-toggle {
      transform: rotate(90deg) !important;
    }
  }

  .title-toggle:focus {
    outline: none;
  }

  .accordion-content {
    padding: 1.5%;
    text-align: center;
    border-bottom: 5px solid black;
    div {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      padding: 0%;
    }
    p {
      margin: 0px;
    }
  }
}

//tablet
@media (max-width: 1025px) {
  .accordion-container {
    background-color: #262626;
    width: 100%;
    font-family: 'erbaum';
    margin: 0%;
  }

  .accordion-row {
    .title-toggle {
      font-size: 2.5vw;
      padding: 2%;
    }

    .accordion-content {
      padding: 2%;
      text-align: center;
      border-bottom: 5px solid black;
      div {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        padding: 0%;
      }
    }
  }
}

// phone
@media (max-width: 767px) {
  .accordion-container {
    background-color: #262626;
    width: 100%;
    font-family: 'erbaum';
    margin: 0%;
  }

  .accordion-row {
    .title-toggle {
      font-size: 5vw;
      padding: 5% 3%;
    }

    .accordion-content {
      padding: 5%;
      text-align: center;
      border-bottom: 5px solid black;
      div {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        padding: 0%;
      }
    }
  }
}
