.course-cards {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

// phone
@media (max-width: 767px) {
  .course-cards {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
}
