.course-list-container {
  text-align: left;
  margin-left: 7%;
  width: 100%;

  .course-list-topic-heading {
    font-family: 'mono45-headline';
    font-size: 1.5vw;
    color: white;
  }

  .current {
    border: white solid 3px;
  }
  .course-heading-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    .adjust-topic-order-btn-container {
      display: flex;
      flex-direction: column;
      margin-left: 15px;
      justify-content: center;
      margin-bottom: 0%;
      .order-arrow-icon {
        font-size: 1vw;
        cursor: pointer;
      }
    }
  }

  .course-list-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .adjust-lesson-order-btn-conatiner {
      display: flex;
      flex-direction: column;
      margin-right: 15px;
      justify-content: center;
      margin-bottom: 2%;
      .order-arrow-icon {
        font-size: 1vw !important;
        cursor: pointer;
      }
    }
  }

  .course-list-lesson-container {
    background-color: #262626;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2%;
    width: 100%;
    .course-list-link {
      color: white;
      text-decoration: none !important;
      width: 100%;
    }
    .course-list-lesson {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      font-family: 'erbaum';
      font-size: 1vw;
      padding: 3%;
      margin-bottom: 0;
      .icon {
        margin-right: 5px;
      }
      .watched {
        color: green;
      }
    }
    .course-list-lesson:hover {
      text-decoration: underline;
    }
    .delete-edit-btn-container {
      display: flex;
      justify-content: flex-end;
      width: 50%;
      .edit-lesson-text {
        width: 100%;
        color: green;
        font-weight: 500;
        text-align: right;
        margin-right: 10px;
        font-size: 0.8vw;
        font-family: 'erbaum';
        margin-bottom: 0;
        cursor: pointer;
      }
      .delete-lesson-text {
        width: 100%;
        color: red;
        font-weight: 500;
        text-align: right;
        margin-right: 10px;
        font-size: 0.8vw;
        font-family: 'erbaum';
        margin-bottom: 0;
        cursor: pointer;
      }
      .delete-lesson-text:hover {
        text-decoration: underline;
      }
    }
  }
}

// tablet
@media (max-width: 1025px) {
  .course-list-container {
    margin-left: 0%;

    .course-list-topic-heading {
      font-size: 4.5vw;
    }

    .course-heading-row {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;

      .adjust-topic-order-btn-container {
        display: flex;
        flex-direction: row;
        margin-left: 15px;
        justify-content: space-between;
        margin-bottom: 0%;
        width: 13%;
        .order-arrow-icon {
          font-size: 5vw;
          cursor: pointer;
        }
      }
    }

    .course-list-row {
      .adjust-lesson-order-btn-conatiner {
        margin-right: 15px;
        justify-content: center;
        margin-bottom: 2%;
        .order-arrow-icon {
          font-size: 5vw !important;
          cursor: pointer;
        }
      }
    }

    .course-list-lesson-container {
      justify-content: space-between;
      margin-bottom: 2%;
      width: 100%;
      .course-list-link {
        width: 50%;
      }
      .course-list-lesson {
        font-size: 2.5vw;
        padding: 5% 3%;
        .icon {
          margin-right: 5px;
        }
      }
      .course-list-lesson:hover {
        text-decoration: underline;
      }
      .delete-edit-btn-container {
        display: flex;
        justify-content: flex-end;
        width: 50%;
        .edit-lesson-text {
          font-size: 2vw;
        }
        .delete-lesson-text {
          font-size: 2vw;
        }
        .delete-lesson-text:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

// phone
@media (max-width: 767px) {
  .course-list-container {
    margin-left: 0%;

    .course-list-topic-heading {
      font-size: 8vw;
    }

    .course-heading-row {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;

      .adjust-topic-order-btn-container {
        display: flex;
        flex-direction: row;
        margin-left: 15px;
        justify-content: space-between;
        width: 20%;
        .order-arrow-icon {
          font-size: 8vw;
        }
      }
    }

    .course-list-row {
      .adjust-lesson-order-btn-conatiner {
        margin-right: 15px;
        justify-content: center;
        margin-bottom: 2%;
        .order-arrow-icon {
          font-size: 8vw !important;
        }
      }
    }

    .course-list-lesson-container {
      justify-content: space-between;
      margin-bottom: 2%;
      width: 100%;
      .course-list-link {
        width: 90%;
      }
      .course-list-lesson {
        font-size: 4.5vw;
        padding: 5% 10%;
        .icon {
          margin-right: 5px;
        }
      }
      .course-list-lesson:hover {
        text-decoration: underline;
      }
      .delete-edit-btn-container {
        display: flex;
        justify-content: flex-end;
        width: 50%;
        .edit-lesson-text {
          font-size: 3.5vw;
        }
        .delete-lesson-text {
          font-size: 3.5vw;
        }
        .delete-lesson-text:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
