.app {
  padding-top: 7.3vw;
  padding-bottom: 7%;
  background-color: #003a52;
}

// tablet
@media (max-width: 1025px) {
  .app {
    padding-top: 14vw;
  }
}

// phone
@media (max-width: 767px) {
  .app {
    padding-top: 15vw;
  }
}
