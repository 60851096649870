.course-container {
  margin: 0 5%;
  margin-top: 3%;
  text-align: center;
  display: flex;
  flex-direction: column;
  color: white;
  .no-videos-found-text {
    font-family: 'erbaum';
    font-size: 1.5vw;
  }
  .main-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 3%;
  }
  .course-details {
    flex-basis: calc(50%);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .course-accordion-gallery {
    flex-basis: calc(50%);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .course-link-back {
    text-align: center;
    width: 70%;
  }
  .course-link-back-text {
    color: white;
    background-color: #000000;
    padding: 3% 0%;
    font-family: 'erbaum';
    font-size: 0.9vw;
    width: 100%;
  }
  .course-link-back:hover {
    color: white;
  }
}

// tablet
@media (max-width: 1025px) {
  .course-container {
    margin: 5% 5%;
  }
  .no-videos-found-text {
    font-size: 3vw !important;
  }
  .course-details {
    align-items: center;
    margin-bottom: 5%;
  }
  .course-title {
    font-size: 5vw !important;
    text-align: center !important;
  }
  .course-desc {
    font-size: 2vw !important;
  }
  .course-link-back {
    text-align: center !important;
    width: 50% !important;
  }
  .course-link-back p {
    padding: 5% !important;
    font-size: 2vw !important;
  }
  .course-details {
    flex-basis: calc(100%) !important;
  }
  .course-accordion-gallery {
    flex-basis: calc(100%) !important;
  }
}

// phone
@media (max-width: 767px) {
  .course-container {
    margin: 5% 5%;
  }
  .no-videos-found-text {
    font-size: 8vw !important;
  }
  .course-details {
    align-items: center;
    margin-bottom: 5%;
  }
  .course-title {
    font-size: 10vw !important;
  }
  .course-desc {
    font-size: 4vw !important;
  }
  .course-link-back {
    width: 70% !important;
  }
  .course-link-back p {
    padding: 5% !important;
    font-size: 4vw !important;
  }
}
