.admin-notice {
  text-transform: uppercase;
  text-decoration: underline;
  font-family: 'erbaum';
  color: green;
  font-size: 1.5vw;
}

// Tablet
@media (max-width: 1025px) {
  .admin-notice {
    font-size: 2.5vw !important;
  }
}

// phone
@media (max-width: 767px) {
  .admin-notice {
    font-size: 4.5vw !important;
    margin-top: 5%;
  }
}
