.footer-bar {
  background-color: #000000;
  width: 100%;

  .main-footer-bar {
    display: flex;
    padding: 1% 2%;
    color: white;
    justify-content: center;
    p {
      line-height: 1;
      margin: 0;
    }
    .footer-logo {
      width: 9vw;
      height: auto;
    }
  }
}

// tablet
@media (max-width: 1025px) {
  .footer-bar {
    .main-footer-bar {
      .footer-logo {
        width: 15vw;
      }
    }
  }
}

// phone
@media (max-width: 767px) {
  .footer-bar {
    .main-footer-bar {
      padding: 5% 0%;
      .footer-logo {
        width: 25vw;
      }
    }
  }
}
