.delete-lesson-popup {
  width: 100%;
  max-width: none !important;
  text-align: center;
  display: flex;
  .modal-dialog {
    max-width: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 10%;
  }
  .modal-content {
    width: 40vw;
  }
  .modal-body {
    background-color: #003a52;
    color: white;
    border: 2px solid white;
    padding: 3% 3% 5% 3%;
    .delete-lesson-name {
      font-family: 'mono45-headline';
      font-size: 2.5vw;
      margin-bottom: 3%;
      line-height: 1;
    }
    .delete-lesson-notice {
      font-size: 0.9vw;
      text-align: left;
      font-family: 'erbaum';
    }
    .delete-lesson-btn-container {
      margin-top: 5%;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      font-family: 'erbaum';
      font-size: 1vw;
      .delete-btn {
        padding: 1% 5%;
      }
      .delete-yes {
        background-color: white;
        color: #003a52;
      }
      .delete-no {
        background-color: #0d0d0d;
        color: white;
      }
    }
  }
}

// tablet
@media (max-width: 1025px) {
  .delete-lesson-popup {
    .modal-content {
      width: 70vw;
    }
    .modal-body {
      .delete-lesson-name {
        font-size: 4.5vw;
      }
      .delete-lesson-notice {
        font-size: 2vw;
      }
      .delete-lesson-btn-container {
        font-size: 2vw;
      }
    }
  }
}

// phone
@media (max-width: 1025px) {
  .delete-lesson-popup {
    .modal-content {
      width: 90vw;
    }
    .modal-body {
      .delete-lesson-name {
        font-size: 8vw;
      }
      .delete-lesson-notice {
        font-size: 4vw;
      }
      .delete-lesson-btn-container {
        margin-top: 5%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        font-family: 'erbaum';
        font-size: 4vw;
        .delete-btn {
          padding: 3% 0%;
          margin-bottom: 10%;
        }
      }
    }
  }
}
