.course-video-container {
  .player-wrapper {
    position: relative;
    padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
  }

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    background-color: black;
  }

  .video-admin-control-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 3%;
  }

  .lesson-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 100%;
    margin-top: 3%;
    margin-right: 5%;
    margin-left: 5%;
    .lesson-detail-container {
      display: flex;
      flex-direction: column;
      color: white;
      flex-basis: calc(45%);
      .lesson-sub-text {
        margin: 0;
        font-family: 'erbaum';
        font-size: 1vw;
        margin: 0;
        text-transform: capitalize;
      }
      .lesson-name {
        font-family: 'mono45-headline';
        font-size: 4vw;
        margin: 0;
      }
      .lesson-date-created {
        font-family: 'erbaum';
        font-size: 1vw;
        margin: 0;
      }
      .lesson-desc {
        font-family: 'erbaum';
        font-size: 0.9vw;
      }
      .lesson-link-back {
        text-align: center;
        width: 70%;
      }
      .lesson-link-back-text {
        color: white;
        background-color: #000000;
        padding: 3% 0%;
        font-family: 'erbaum';
        font-size: 0.9vw;
        width: 100%;
        text-transform: capitalize;
      }
      .lesson-link-back:hover {
        color: white;
      }
    }
    .course-list-container {
      flex-basis: calc(45%);
    }
  }
}

// tablet
@media (max-width: 1025px) {
  .course-video-container {
    .lesson-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
      max-width: 100%;
      margin-bottom: 3% 5%;
      .lesson-detail-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: white;
        flex-basis: calc(100%);
        margin-bottom: 7%;
        .lesson-sub-text {
          font-size: 3vw;
        }
        .lesson-name {
          font-size: 8vw;
        }
        .lesson-desc {
          font-family: 'erbaum';
          font-size: 2.5vw;
        }
        .lesson-link-back {
          text-align: center;
          width: 70%;
          margin-top: 5%;
        }
        .lesson-link-back-text {
          padding: 3% 0%;
        }
      }
      .course-list-container {
        flex-basis: calc(100%);
      }
    }
  }
}

// phone
@media (max-width: 1025px) {
  .course-video-container {
    .lesson-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
      max-width: 100%;
      margin-bottom: 3% 5%;
      .lesson-detail-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: white;
        flex-basis: calc(100%);
        margin-bottom: 7%;
        .lesson-sub-text {
          font-size: 5vw;
        }
        .lesson-name {
          font-size: 10vw;
        }
        .lesson-desc {
          font-family: 'erbaum';
          font-size: 4.5vw;
        }
        .lesson-link-back {
          text-align: center;
          width: 100%;
          margin-top: 5%;
        }
        .lesson-link-back-text {
          padding: 3% 0%;
          text-transform: capitalize;
          font-size: 4vw;
        }
      }
      .course-list-container {
        flex-basis: calc(100%);
      }
    }
  }
}
