.error-container {
  text-align: center;
  font-family: 'erbaum';
  margin-top: 5%;
  .error-msg {
    color: white;
    font-size: 2vw;
  }
  .error-return-home {
    font-size: 1.3vw;
    padding: 0.5% 2%;
  }
}

// tablet
@media (max-width: 1025px) {
  .error-container {
    margin-top: 5%;
    .error-msg {
      font-size: 4vw;
    }
    .error-return-home {
      font-size: 2.5vw;
      padding: 0.5% 2%;
    }
  }
}

// phone
@media (max-width: 767px) {
  .error-container {
    margin-top: 5%;
    .error-msg {
      font-size: 7vw;
    }
    .error-return-home {
      font-size: 5vw;
      padding: 1% 5%;
    }
  }
}
