.course-container {
  margin: 0 10%;
  margin-top: 3%;
  text-align: center;
  display: flex;
  flex-direction: column;
  color: white;
  .no-videos-found-text {
    font-family: 'erbaum';
    font-size: 1.5vw;
  }
  .auth-error-text {
    font-family: 'erbaum';
    font-size: 1.3vw;
    padding: 5%;
  }
  .main-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 3%;
    .no-course-msg {
      font-family: 'erbaum';
      font-size: 2vw;
      text-align: center;
    }
  }
  .course-details {
    flex-basis: calc(50%);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-right: 5%;
  }
  .course-video-list {
    flex-basis: calc(50%);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .completion-bar-text {
    font-family: 'mono45-headline';
    font-size: 2vw;
    margin-bottom: 0;
  }
  .completion-bar-percent {
    font-family: 'erbaum';
    font-size: 1vw;
  }
  .completion-bar {
    border-radius: 25px;
    height: 3rem;
  }
  .course-title {
    font-family: 'mono45-headline';
    font-size: 4vw;
    text-align: left;
  }
  .course-desc {
    font-family: 'erbaum';
    font-size: 0.9vw;
    font-weight: 500;
    text-align: justify;
  }
  .course-link-back {
    text-align: center;
    width: 70%;
  }
  .course-link-back-text {
    color: white;
    background-color: #000000;
    padding: 3% 0%;
    font-family: 'erbaum';
    font-size: 0.9vw;
    width: 100%;
  }
  .course-link-back:hover {
    color: white;
  }
}

// tablet
@media (max-width: 1025px) {
  .no-course-msg {
    font-size: 3vw !important;
  }
  .course-container {
    margin: 5% 5%;
  }
  .no-videos-found-text {
    font-size: 3vw !important;
  }
  .auth-error-text {
    font-family: 'erbaum';
    font-size: 3vw !important;
    padding: 5%;
  }
  .course-details {
    align-items: center;
    margin-bottom: 5%;
    padding-right: 0%;
  }
  .course-title {
    font-size: 5vw !important;
    text-align: center !important;
  }
  .course-desc {
    font-size: 2vw !important;
  }
  .course-link-back {
    text-align: center !important;
    width: 50% !important;
  }
  .course-link-back p {
    padding: 5% !important;
    font-size: 2vw !important;
  }
  .course-details {
    flex-basis: calc(100%) !important;
  }
  .course-video-list {
    flex-basis: calc(100%) !important;
  }
  .completion-bar-text {
    font-size: 4vw !important;
  }
  .completion-bar-percent {
    font-size: 2.5vw !important;
  }
  .completion-bar {
    height: 2rem !important;
  }
}

// phone
@media (max-width: 767px) {
  .no-course-msg {
    font-size: 5vw !important;
  }
  .course-container {
    margin: 5% 5%;
  }
  .no-videos-found-text {
    font-size: 5vw !important;
    padding: 5% 0% !important;
    text-align: justify;
  }
  .auth-error-text {
    font-size: 5vw !important;
    padding: 5% 0% !important;
    text-align: justify;
  }
  .course-details {
    align-items: center;
    margin-bottom: 5%;
    padding-right: 0%;
  }
  .course-title {
    font-size: 10vw !important;
  }
  .course-desc {
    font-size: 4vw !important;
  }
  .course-link-back {
    width: 70% !important;
  }
  .course-link-back p {
    padding: 5% !important;
    font-size: 4vw !important;
  }
  .completion-bar-text {
    font-size: 7vw !important;
  }
  .completion-bar-percent {
    font-size: 4vw !important;
  }
  .completion-bar {
    height: 1.5rem !important;
  }
}
