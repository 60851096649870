.course-card {
  background-color: #0d0d0d;
  padding: 2% 5%;
  border-radius: 15px;
  margin: 2%;
  display: flex;
  flex-direction: column;
  flex-basis: calc(45%);
  justify-content: center;

  h3 {
    font-size: 3.5vw;
    text-transform: uppercase;
    font-family: 'mono45-headline';
  }

  .course-overview {
    text-decoration: underline;
    font-size: 1.3vw;
    margin-top: 3%;
    cursor: pointer;
  }

  .view-course {
    background-color: #54595f;
    border-radius: 5px;
    width: 50%;
    align-self: center;
    padding: 2% 7%;
    margin-top: 5%;
    font-size: 1vw;
    color: white;
  }
  .view-course p {
    margin-bottom: 0px;
  }
  .view-course:hover {
    text-decoration: none;
  }
}

// Tablet
@media (max-width: 1025px) {
  .course-card {
    background-color: #0d0d0d;
    padding: 5% 3%;
    border-radius: 15px;
    margin: 2%;
    display: flex;
    flex-direction: column;
    flex-basis: calc(45%);
    justify-content: center;

    h3 {
      font-size: 4.5vw;
    }

    .course-overview {
      text-decoration: underline;
      font-size: 2vw;
      margin-top: 0%;
      cursor: pointer;
    }

    .view-course {
      width: 80%;
      padding: 4% 0%;
      margin-top: 5%;
      font-size: 2vw;
      color: white;
    }
    .view-course:hover {
      text-decoration: none;
    }
  }
}

// phone
@media (max-width: 767px) {
  .course-card {
    background-color: #0d0d0d;
    padding: 7% 3%;
    border-radius: 15px;
    margin: 2%;
    flex-basis: calc(100%);
    justify-content: center;
    margin-bottom: 5%;

    h3 {
      font-size: 8.5vw;
    }

    .course-overview {
      text-decoration: underline;
      font-size: 4.5vw;
      margin-top: 0%;
      cursor: pointer;
    }

    .view-course {
      width: 65%;
    }
    .view-course p {
      font-size: 4.5vw;
    }
    .view-course:hover {
      text-decoration: none;
    }
  }
}
