.edit-lesson-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: 'erbaum';
  .form-label {
    text-align: left;
    font-size: 1vw;
  }
  input,
  textarea {
    font-size: 0.7vw;
    color: black;
    padding: 2% 2%;
    margin-bottom: 2%;
  }
  input::placeholder,
  textarea::placeholder {
    color: black;
  }

  .edit-lesson-btn-container {
    margin-top: 5%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    font-family: 'erbaum';
    font-size: 1vw;
    .edit-btn {
      padding: 1% 5%;
    }
    .edit-yes {
      background-color: white;
      color: #003a52;
    }
    .edit-no {
      background-color: #0d0d0d;
      color: white;
    }
  }
}

// tablet
@media (max-width: 1025px) {
  .add-lesson-form {
    .form-label {
      font-size: 2vw;
    }
    input,
    textarea {
      font-size: 1.7vw;
    }

    .edit-lesson-btn-container {
      margin-top: 5%;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      font-family: 'erbaum';
      font-size: 2vw;
      .edit-btn {
        padding: 1% 5%;
      }
      .edit-lesson-btn {
        margin-top: 25px;
        align-self: center;
        font-size: 1vw;
        background-color: white;
      }
      .edit-yes {
      }
      .edit-no {
        background-color: #0d0d0d;
        color: white;
      }
    }
  }
}

//phone
@media (max-width: 767px) {
  .edit-lesson-form {
    .form-label {
      font-size: 4.5vw;
    }
    input,
    textarea {
      font-size: 4vw;
    }

    .edit-lesson-btn-container {
      margin-top: 5%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      font-family: 'erbaum';
      font-size: 2vw;
      .edit-btn {
        padding: 3% 0%;
        font-size: 4vw;
        margin-bottom: 10%;
      }
      .edit-yes {
        margin-top: 5%;
      }
      .edit-no {
        background-color: #0d0d0d;
        color: white;
      }
    }
  }
}
