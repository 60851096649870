.edit-lesson-popup {
  width: 100%;
  max-width: none !important;
  text-align: center;
  display: flex;
  .modal-dialog {
    max-width: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 10%;
  }
  .modal-content {
    width: 40vw;
  }
  .modal-body {
    background-color: #003a52;
    color: white;
    border: 2px solid white;
    padding: 3% 3% 5% 3%;
    .edit-lesson-name {
      font-family: 'mono45-headline';
      font-size: 2.5vw;
      margin-bottom: 3%;
    }
  }

  .form-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
  }
}

// tablet
@media (max-width: 1025px) {
  .edit-lesson-popup {
    .modal-content {
      width: 70vw;
    }
    .modal-body {
      .edit-lesson-name {
        font-size: 4vw;
      }
    }
  }
}

// phone
@media (max-width: 767px) {
  .edit-lesson-popup {
    .modal-content {
      width: 90vw;
    }
    .modal-body {
      .edit-lesson-name {
        font-size: 6vw;
      }
    }
  }
}
