.admin-notice {
  text-transform: uppercase;
  text-decoration: underline;
  font-family: 'erbaum';
  color: green;
  font-size: 1.5vw;

  text-align: center;
}
.admin-content-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  .admin-control-tip {
    display: none;
    font-family: 'erbaum';
    font-size: 0.9vw;
  }
  .admin-button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 5%;
    margin-left: 7%;
    .admin-button {
      font-family: 'erbaum';
      font-size: 1vw;
      padding: 1% 5%;
    }
    .add-lesson {
      background-color: green;
      color: white;
      text-align: right;
      .icon {
        margin-right: 5px;
      }
    }
    .delete-lesson-mode {
      text-align: right;
      background-color: red;
      color: white;
    }
  }
}

// tablet
@media (max-width: 1025px) {
  .admin-content-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    .admin-control-tip {
      display: none;
      font-family: 'erbaum';
      font-size: 2vw;
    }
    .admin-button-container {
      margin-left: 0%;
      .admin-button {
        font-size: 2vw;
      }
    }
  }
}

// phone
@media (max-width: 767px) {
  .admin-content-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    .admin-control-tip {
      display: none;
      font-family: 'erbaum';
      font-size: 4.5vw;
    }
    .admin-button-container {
      flex-direction: column;
      margin-left: 0%;
      .admin-button {
        font-size: 4.5vw;
        text-align: center;
        padding: 3%;
        margin-bottom: 5%;
      }
    }
  }
}
