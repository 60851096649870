.course-overview-popup {
  width: 100%;
  max-width: none !important;
  text-align: center;
  display: flex;

  .modal-dialog {
    max-width: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 7%;
  }
  .modal-content {
    width: 40vw;
  }
  .modal-body {
    background-color: #003a52;
    color: white;
    border: 2px solid white;
    h3 {
      font-family: 'mono45-headline';
      font-size: 3vw;
    }
  }

  .overview-item-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-left: 5%;
  }

  .overview-item {
    flex-basis: calc(45%);
    font-family: 'erbaum';
    font-size: 1vw;
    text-align: left;
    margin-right: 25px;
  }
}

// Tablet
@media (max-width: 1025px) {
  .course-overview-popup {
    .modal-content {
      width: 70vw;
    }
    .modal-body {
      h3 {
        font-size: 5vw;
      }
    }

    .overview-item {
      flex-basis: calc(45%);
      font-size: 2vw;
      margin-right: 5%;
    }
  }
}

// Phone
@media (max-width: 767px) {
  .course-overview-popup {
    .modal-content {
      width: 80vw;
    }
    .modal-body {
      h3 {
        font-size: 10vw;
        line-height: 1;
        margin-bottom: 10%;
      }
    }

    .overview-item {
      flex-basis: calc(100%);
      font-size: 4vw;
      margin-right: 0%;
    }
  }
}
