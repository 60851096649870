.all-courses-page {
  color: white;
  text-align: center;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 10%;
  margin-top: 3%;

  h2,
  p {
    margin: 0;
  }

  h2 {
    font-size: 5vw;
    font-weight: bold;
    font-family: 'mono45-headline';
  }

  p {
    font-size: 1.2vw;
    margin: 0 5%;
    margin-bottom: 3%;
    font-family: 'erbaum';
  }
}

// Tablet
@media (max-width: 1025px) {
  .all-courses-page {
    margin: 0 7%;
    margin-top: 3%;

    h2,
    p {
      margin: 0;
    }

    h2 {
      font-size: 7vw;
    }

    p {
      font-size: 2vw;
      margin: 0 3%;
      margin-bottom: 3%;
    }
  }
}

// Phone
@media (max-width: 767px) {
  .all-courses-page {
    margin: 0 5%;
    margin-top: 3%;

    h2 {
      font-size: 8vw;
      line-height: 1;
    }

    .all-course-paragraph {
      font-size: 4vw;
      text-align: justify;
      margin: 0 2%;
      margin-top: 3%;
      margin-bottom: 5%;
    }
  }
}
