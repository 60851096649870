.image-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-basis: calc(50%);
  padding: 1%;
  margin-bottom: 2%;
  .image {
    width: 20vw;
    height: 100%;
    cursor: pointer;
    text-align: center;
  }

  .image-text {
    text-align: center;
    padding-top: 10px;
    font-size: 1vw;
  }
}

// tablet
@media (max-width: 1025px) {
  .image-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-basis: calc(50%);
    padding: 1%;
    margin-bottom: 2%;
    .image {
      width: 40vw;
    }

    .image-text {
      font-size: 2vw;
    }
  }
}

// phone
@media (max-width: 767px) {
  .image-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-basis: calc(100%);
    padding: 1%;
    margin-bottom: 2%;
    .image {
      width: 70vw;
    }

    .image-text {
      font-size: 4.5vw;
    }
  }
}
