.navigation-bar {
  font-family: 'erbaum';
  position: fixed;
  width: 100%;
  z-index: 999;
  background-color: #0d0d0d;
  .top-nav-bar {
    background-color: #262626;
    padding: 1% 2%;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 0.8vw;

    a {
      font-weight: bold;
    }
    a:link {
      color: white;
    }
  }
  .main-nav-bar {
    padding: 0.5% 2%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .main-nav-bar-right {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      .username-text {
        color: white;
        font-size: 1vw;
        margin-bottom: 0;
      }
      .log-out-btn {
        margin-bottom: 0;
        font-family: 'erbaum';
        color: red;
        font-size: 1vw;
        text-decoration: underline;
        cursor: pointer;
        margin-left: 5%;
      }
    }
  }
  .nav-logo {
    width: 7vw;
    height: auto;
  }
}

// Tablet
@media (max-width: 1025px) {
  .navigation-bar {
    .top-nav-bar {
      font-size: 1.7vw;
      padding: 2% 3%;
    }
    .main-nav-bar {
      padding: 0.5% 3%;
      .main-nav-bar-right {
        width: 100%;
        .username-text {
          font-size: 1.7vw;
        }
        .log-out-btn {
          font-size: 1.7vw;
        }
      }
    }
    .nav-logo {
      width: 15vw;
    }
  }
}

// Phone
@media (max-width: 767px) {
  .navigation-bar {
    .top-nav-bar {
      display: none;
    }
    .main-nav-bar {
      padding: 2% 5%;
      .main-nav-bar-right {
        width: 100%;
        justify-content: space-between;
        .username-text {
          font-size: 4vw;
          margin-left: 7%;
        }
        .log-out-btn {
          font-size: 4vw;
        }
      }
    }
    .nav-logo {
      width: 25vw;
    }
  }
}
